<template>
    <div class="page-with-top-bar">
        <project-admin-quick-buttons hiddenOption="cost_budget" />
        <section>
            <div class="mt-4">
                <b-tabs type="is-boxed" v-model="mainActiveTab">
                    <!-- <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="dashboard"></b-icon>
                            <span>{{ $t("Dashboard") }}</span>
                        </template>
<div>
    <manage-dashboard :projectId="project_id"></manage-dashboard>
</div>
</b-tab-item> -->
                    <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="tasks"></b-icon>
                            <span> {{ $t("breakdown_by_assignment") }}</span>
                        </template>
                        <div>
                            <manage-assignment-breakdown ref="manage_assignment_breakdown" :projectId="project_id"
                                :providers="providers" :company="company.id" @reload-list="handleReloadCost" />
                        </div>
                    </b-tab-item>
                    <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="cubes"></b-icon>
                            <span> {{ $t("breakdown") }}</span>
                        </template>
                        <div>
                            <cost-management ref="manage_budget" :projectId="project_id" :providers="providers"
                                :company="company.id" @reload-list="handleReloadBreakDown" />
                        </div>
                    </b-tab-item>
                    <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="users"></b-icon>
                            <span> {{ $t("providers") }} </span>
                        </template>
                        <div>
                            <manage-providers :projectId="project_id" @epc-store-providers="handleStoreProviders" />
                        </div>
                    </b-tab-item>
                    <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="file-invoice"></b-icon>
                            <span> {{ $t("billing") }} </span>
                        </template>
                        <div>
                            <manage-billing :projectId="project_id" @epc-store-products="handleStoreProducts" />
                        </div>
                    </b-tab-item>
                    <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="file-invoice"></b-icon>
                            <span> {{ $t("indirect_costs") }} </span>
                        </template>
                        <div>
                            <manage-indirect-cost :projectId="project_id" />
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import importEntities from "../../components/import-entities";
// import exportActivities from "../../components/export-activities";
import ProjectAdminQuickButtons from "·/components/project-admin-quick-buttons";
import CostManagement from "../../components/cost-management/manage-cost-management.vue";
import ManageBilling from "../../components/cost-management/manage-billing.vue";
import ManageIndirectCost from "../../components/cost-management/manage-indirect_cost.vue";
import ManageProviders from "../../components/storehouse/manage-providers.vue";
// import ManageDashboard from "../../components/cost-management/manage-dashboard.vue";
import ManageAssignmentBreakdown from "../../components/cost-management/assignment-breakdown/manage-assignment-breakdown.vue";
export default {
    components: {
        ProjectAdminQuickButtons,
        CostManagement,
        ManageProviders,
        ManageBilling,
        ManageIndirectCost,
        // ManageDashboard
        ManageAssignmentBreakdown
    },
    middleware: "auth",
    title: "cost_budget",
    menuOption: "0-2",

    computed: {
        ...mapGetters({
            navData: "app/navData",
            company: "app/company"
        })
    },

    data: () => ({
        project_id: null,
        mainActiveTab: 0,
        products: [],
        providers: null,
        activities: []
    }),

    created() {
        this.project_id = this.navData.project_id;

        this.$options.subtitle = ["project_actions_page_subtitle", [this.navData.project_name.toUpperCase()]];
        const tab = this.$route.query.tab;
        if (tab === 'billing') {
            this.mainActiveTab = 3; // Índice del tab "billing"
        }
        if (tab === 'indirect-cost') {
            this.mainActiveTab = 4; // Índice del tab "indirect cost"
        }
    },
    methods: {
        handleStoreProducts(e) {
            this.products = e;
        },
        handleStoreProviders(e) {
            this.handleReloadBreakDown();
            this.handleReloadCost();
        },

        handleUpdatedList(e) {
            // si componente purchases & outs emiten evento updated list actualizamos la lista del inventario aplicando sus filtros
            this.$refs.manage_inventories.filtersUpdate();
        },
        handleReloadBreakDown() {
            this.$refs.manage_assignment_breakdown.handleReload();
            this.$refs.manage_assignment_breakdown.getProviders();
        },
        handleReloadCost() {
            this.$refs.manage_budget.handleReloadEdit();
            this.$refs.manage_budget.getProviders();
        }

    }
};
</script>
