<template>
  <div class="bg-white">
    <div class="flex text-center uppercase w-full text-lg">
      <h4 class="w-full" v-if="isLoadingTable"><b> Cargando {{ $t('activities') }}... </b></h4>
      <h4 class="w-full" v-else><b><span v-if="total">{{ total }}</span> {{ $t('activities') }}</b></h4>
    </div>

    <div class="w-full h-8" v-if="false"> <!--  desactividado de momento por refactorizacion -->
      <!-- Filtro por subactividades -->
      <div class="flex float-left">
        <v-switch
          :disabled="isLoadingTable"
          :focusable="! isLoadingTable"
          :label="$t('filter_by_subactivities')"
          :color="filterSubactivities ? 'green' : 'red'"
          :value="filterSubactivities"
          v-on:checked="checkFilterSubactivities"
          style="margin-left: 20px"
        />
      </div>
      <div v-if="false"> <!-- desactividado de momento por refactorizacion -->
        <!-- Botones, sólo Jefes de departamento -->
        <div class="flex float-right">
            <div class="mr-2 rounded-sm">
              <v-button icon="layer-group" class="mr-4" @click="goToPage('groups')">{{ $t('activity_groups') }}</v-button>
            </div>

            <div class="mr-2 rounded-sm">
              <v-button icon="file-pdf" class="mr-4" @click="goToPage('drawings')">{{ $t('drawings') }}</v-button>
            </div>

            <div class="mr-2 rounded-sm" v-if="project.user_department.can_admin">
              <v-button icon="plus" class="mr-4" @click="onCreateActivityClicked()">{{ $t('add_activity') }}</v-button>
            </div>

            <!-- Botón para abrir diagrama de gantt-->
            <div class="mr-2 rounded-sm">
              <b-tooltip :label="$t('display_gantt')" position="is-top">
                <v-button icon="project-diagram" class="" @click="displayGantt()"></v-button>
              </b-tooltip>
            </div>

            <!-- Botón para ver Kanban-->
            <div class="mr-2 rounded-sm" v-if="project.user_department.can_admin">
              <b-tooltip :label="$t('display_kanban')" position="is-top">
                <v-button icon="columns" class="" @click="displayKanban()"></v-button>
              </b-tooltip>
            </div>

            <!-- Botón para ver Carpetas documentos -->
            <div class="mr-2 rounded-sm">
              <b-tooltip :label="$t('display_folders')" position="is-top">
                <v-button icon="folder-plus" class="" @click="$router.push({name: 'project.activities.folders'});"></v-button>
              </b-tooltip>
            </div>

            <template v-if="project.user_department.can_admin">
              <project-report v-if="!isLoadingTable" :filters="this.generatedFilters"/>
              <project-report-excel v-if="showExcelReport"/>
            </template>

            <!-- Botón Lotes canal exportar CSV proyecto -->
            <project-report-lotes v-if="showLotesReport" />

            <!--<div class="p-1 rounded-sm bg-blue text-grey-lightest">
              <b-icon pack="fas" icon="check-circle" size="is-medium" />
            </div>-->
        </div>
      </div>

    </div>


    <div class="text-sm panel_"> <!-- class panel desactividado de momento por refactorizacion -->
      <template>
        <!-- Contador de resultados -->
        <div v-if="false">
          <div class="flex flex-row w-full items-center pl-2 pr-2 mt-1 mb-2">
            <div class="w-4/6">
              <b-field grouped group-multiline>
                <div v-for="(column, index) in columns"
                    :key="index"
                    class="control">
                  <b-checkbox v-model="column.visible" class="text-xs">
                    <span>{{ $t(column.title) }}</span>
                  </b-checkbox>
                </div>
              </b-field>
            </div>

            <div class="w-2/6">
              <b-select v-model="perPage" @input="filtersUpdated" size="is-small" class="float-right">
                <option value="10">{{ $t('per_page', {amount: 10}) }}</option>
                <option value="25">{{ $t('per_page', {amount: 25}) }}</option>
                <option value="50">{{ $t('per_page', {amount: 50}) }}</option>
              </b-select>
            </div>
          </div>
          <div v-if="total < -1" class="float-right w-full text-right"> <!--desactividado de momento por refactorizacion -->
            <b>{{ $t('count_results') }}: {{ total }}</b>
          </div>
        </div>

        <b-table
          :data="list"
          :loading="isLoadingTable"

          paginated
          pagination-size="is-small"
          backend-pagination
          :per-page="perPage"
          :current-page.sync="currentPage"
          :total="total"
          @page-change="onPageChange"

          backend-sorting
          :default-sort="orderField"
          :default-sort-direction="orderDir"
          @sort="onSort"

          detailed
          detail-key="id"
          icon-pack="fas"
          :has-detailed-visible="displaySubactivitiesOrAssignments"
          :opened-detailed="[this.openedSubactivitiesId]"
          @details-open="openDetails"
          @details-close="(row, index) => this.openedSubactivitiesId = 0"

          class="text-xs border-blue-lighter"
          bordered
          narrowed
          striped
          :row-class="getRowClass"
        >

          <template slot="empty">
            <div v-if="! isLoadingTable" class="m-4 text-lg text-dark-grey">
              <span>{{ $t('empty_activities_table_msg') }}</span>
            </div>
          </template>

          <template>

            <b-table-column field="star" cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-star"></i>
              </template>
              <template v-slot="activities">
                <div v-if="!activities.row.favorite" @click="addFavorite(activities.row.id, activities.index)"
                    class="cursor-pointer">
                  <b-icon icon="star" pack="fas" size="is-small" class="text-grey"/>
                </div>

                <div v-if="activities.row.favorite" @click="deleteFavorite(activities.row.id, activities.index)"
                    class="cursor-pointer">
                  <b-icon icon="star" pack="fas" size="is-small" class="text-yellow-dark"/>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="in_planning" label="P" :visible="columns['in_planning'].visible"
                            cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-road"></i>
              </template>
              <template v-slot="activities">
                <div v-if="activities.row.in_planning">
                  <b-tooltip :label="$t('in_planning')">
                    <b-icon icon="road" pack="fas" size="is-small" class="text-green"/>
                  </b-tooltip>
                </div>
                <div v-if="!activities.row.in_planning">
                  <b-tooltip :label="$t('out_planning')">
                    <b-icon icon="road" pack="fas" size="is-small" class="text-grey"/>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="mobile" cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-mobile-alt"></i>
              </template>
              <template v-slot="activities">
                <div v-if="!activities.row.show_on_devices" @click="showOnDevice(activities.row.id, activities.index)"
                    :class="{'cursor-pointer': project.can_admin}">
                  <b-icon icon="mobile-alt" pack="fas" size="is-small" class="text-grey"/>
                </div>

                <div v-if="activities.row.show_on_devices" @click="hideOnDevice(activities.row.id, activities.index)"
                    class="{'cursor-pointer': project.can_admin}">
                  <b-icon icon="mobile-alt" pack="fas" size="is-small" class="text-green"/>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="group" cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-layer-group"></i>
              </template>
              <template v-slot="activities">
                <div v-if="activities.row.group"
                  class="relative inline-flex items-center justify-center cursor-pointer w-7 h-7 overflow-hidd rounded-full"
                    :style="{'background-color' : colorGroup(activities.row.group) }">
                  <b-tooltip :label="activities.row.group.name"
                    position="is-right"
                    multilined>
                    <span class="font-medium text-white">{{ nameGroup(activities.row.group) }}</span>
                    <template v-slot:content>
                      <div><b>Grupo: </b> {{ activities.row.group.name }}</div>
                    </template>
                  </b-tooltip>
                </div>
                <!-- <div v-else
                  class="relative inline-flex items-center justify-center w-7 h-7 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                  <b-tooltip :label="$t('without_group')"
                    position="is-right">
                    <span class="font-medium text-gray-600 dark:text-gray-300">SG</span>
                  </b-tooltip>
                </div> -->
              </template>
            </b-table-column>

            <b-table-column field="user" label="" :visible="columns['user'].visible" cell-class="w-12 vertical-center"
                            centered>
              <template v-slot:header="">
                <i class="fas fa-user"></i>
              </template>
              <template v-slot="activities">
                <b-tooltip v-if="activities.row.manager && activities.row.manager.user_id"
                          :label="activities.row.manager.fullname">
                  <img
                    :src="activities.row.manager.avatar"
                    class="w-6 h-6 overflow-hidden rounded-full">
                </b-tooltip>
              </template>
            </b-table-column>

            <b-table-column field="planning_code" :visible="columns['planning_code'].visible" :label="$t('code')"
                            sortable cell-class="vertical-center" v-slot="activities">
              <div>
                {{ activities.row.planning_code }}
              </div>
            </b-table-column>

            <b-table-column field="name" :visible="columns['name'].visible" :label="$t('name')" sortable
                            cell-class="vertical-center" v-slot="activities">
                <div class="cursor-pointer"
                     @click="onRowClicked(activities.row.is_subactivity, activities.row.parent_id, activities.row.id)">
                  <activity-time-status :date="activities.row.current_end_date" class="mr-2"
                                        :status="activities.row.status"
                                        v-if="activities.row.status != 4 && activities.row.status != 6"/>
                  <b-tooltip v-if="activities.row.critical_path != null" position="is-right"
                             :label="$t('critical_path')">
                    <b-icon class="fa is-small fa-project-diagram" style="color:#e3342f"></b-icon>
                  </b-tooltip>
                  <template v-if="activities.row.parent !== null">
                    <b-tooltip :label="activities.row.parent !== null ? activities.row.parent.name : ''" position="is-top"
                          multilined>
                    {{ activities.row.name }}
                    </b-tooltip>
                  </template>
                  <template v-else>
                    {{ activities.row.name }}
                  </template>
                </div>
            </b-table-column>

            <b-table-column field="progress" :label="progressLabel" :visible="columns['progress'].visible"
                            cell-class="w-32 vertical-center" centered sortable v-slot="activities">
              <progress-bar
                :step="activities.row.progress"
                color="blue-light"
                class="w-full"
              />
            </b-table-column>

            <b-table-column v-if="project.can_progress" field="progress_actual" :label="$t('actual_progress')" :visible="columns['progress'].visible"
                            cell-class="w-32 vertical-center" centered sortable v-slot="activities">
              <progress-bar
                :step="activities.row.progress_actual"
                color="blue-light"
                class="w-full"
              />
            </b-table-column>

            <b-table-column field="current_start_date" :label="$t('start')"
                            :visible="columns['current_start_date'].visible" cell-class="w-25 vertical-center" sortable
                            centered v-slot="activities">
              {{ activities.row.current_start_date }}
            </b-table-column>

            <b-table-column field="current_end_date" :label="$t('end')" :visible="columns['current_end_date'].visible"
                            cell-class="w-25 vertical-center" sortable centered v-slot="activities">
              {{ activities.row.current_end_date }}
            </b-table-column>

            <b-table-column field="status" :label="$t('status')" cell-class="w-32 vertical-center"
                            :visible="columns['status'].visible" sortable centered v-slot="activities">
              <activity-status-label :status="activities.row.status" class="w-full" style="vertical-align: middle"/>
            </b-table-column>

            <b-table-column v-if="hasStatusList()" field="status" :label="$t('custom_status')"
                            cell-class="w-32 vertical-center"
                            :visible="columns['status_id'].visible"
                            sortable centered v-slot="activities">
              {{ getStatusIdName(activities.row.status_id) }}
            </b-table-column>

            <b-table-column field="notifications" :label="$t('notifications')"
                            :visible="columns['notifications'].visible" cell-class="w-12 vertical-center"
                            sortable centered>
              <template v-slot:header="">
                <i class="fas fa-bell"></i>
              </template>
              <template v-slot="activities">
                <entity-notifications
                  :entityId="activities.row.id"
                  entityType="activity"
                  :mentions="activities.row.notifications.mentions"
                  :change_status="activities.row.notifications.change_status"
                  :comments="activities.row.notifications.comments"
                  :documents="activities.row.notifications.documents"
                  :assignments_warnings="activities.row.notifications.assignments_warnings"
                  :enabledChangedStatus="true"
                />
              </template>
            </b-table-column>

            <b-table-column field="id" numeric cell-class="is-hidden" :visible="false" v-slot="activities">
              {{ activities.row.id }}
            </b-table-column>

          </template>

          <template slot="detail" slot-scope="activities">

            <!--Mediciones -->
            <div class="mb-1" v-if="displayAssignments(activities.row)">
              <div>
                <h4>
                  <b>{{ $t('measurements') }}</b>
                </h4>
              </div>

              <assignment-table
                :list="activities.row.assignments"
                :entityId="activities.row.id"
                :entityBlockedAssignments="activities.row.blocked_assignments == 1"
                :apiProject="apiProject"
                @reload="reloadActivities"/>
            </div>

            <!-- Subactividades -->
            <div v-if="displaySubactivities(activities.row)">

              <div>
                <h4>
                  <b>{{ activities.row.subactivities_num }} {{
                      activities.row.subactivities_num > 1 ?
                        $t('subactivities') : $t('subactivity')
                    }}</b>
                </h4>
              </div>

              <template>
                <b-table
                  ref="subactivities"
                  :data="getSubactivities(activities.row)"
                  default-sort="notifications"
                  default-sort-direction="desc"
                  bordered
                  class="text-xs border-blue-lighter bg-grey-lighter subactivities"
                  narrowed

                  detailed
                  detail-key="id"
                  icon-pack="fas"
                  :has-detailed-visible="displaySubactivitiesOrAssignments"

                  :row-class="(row, index) => row.access ? '' : 'disabled-subactivity'">

                  <template>

                    <b-table-column field="star" cell-class="w-8 vertical-center" centered sortable
                                    :custom-sort="sortByFavorite">
                      <template v-slot:header="">
                        <i class="fas fa-sm fa-star"></i>
                      </template>
                      <template v-slot="subactivities_info">
                        <div v-if="!subactivities_info.row.favorite"
                            @click="addFavorite(subactivities_info.row.id, subactivities_info.index, true)"
                            class="cursor-pointer">
                          <b-icon icon="star" pack="fas" size="is-small" class="text-grey"/>
                        </div>
                        <div v-if="subactivities_info.row.favorite"
                            @click="deleteFavorite(subactivities_info.row.id, subactivities_info.index, true)"
                            class="cursor-pointer">
                          <b-icon icon="star" pack="fas" size="is-small" class="text-yellow-dark"/>
                        </div>
                      </template>
                    </b-table-column>

                    <b-table-column field="in_planning" :visible="columns['in_planning'].visible" label="P"
                                    cell-class="w-8 vertical-center" centered sortable >
                      <template v-slot:header="">
                        <i class="fas fa-sm fa-road"></i>
                      </template>
                      <template v-slot="subactivities_info">
                        <div v-if="subactivities_info.row.in_planning">
                          <b-tooltip :label="$t('in_planning')">
                            <b-icon icon="road" pack="fas" size="is-small" class="text-green"/>
                          </b-tooltip>
                        </div>
                        <div v-if="!subactivities_info.row.in_planning">
                          <b-tooltip :label="$t('out_planning')">
                            <b-icon icon="road" pack="fas" size="is-small" class="text-grey"/>
                          </b-tooltip>
                        </div>
                      </template>
                    </b-table-column>

                    <b-table-column field="mobile" cell-class="w-8 vertical-center" centered sortable
                                    :custom-sort="sortByShowOnDevices">
                      <template v-slot:header="">
                        <i class="fas fa-sm fa-mobile-alt"></i>
                      </template>
                      <template v-slot="subactivities_info">
                        <div v-if="!subactivities_info.row.show_on_devices"
                            @click="showOnDevice(subactivities_info.row.id, subactivities_info.index, true)"
                            :class="{'cursor-pointer': project.can_admin}">
                          <b-icon icon="mobile-alt" pack="fas" size="is-small" class="text-grey"/>
                        </div>
                        <div v-if="subactivities_info.row.show_on_devices"
                            @click="hideOnDevice(subactivities_info.row.id, subactivities_info.index, true)"
                            :class="{'cursor-pointer': project.can_admin}">
                          <b-icon icon="mobile-alt" pack="fas" size="is-small" class="text-green"/>
                        </div>
                      </template>
                    </b-table-column>

                    <b-table-column field="user" :visible="columns['user'].visible" label=""
                                    cell-class="w-12 vertical-center" centered >
                      <template v-slot:header="">
                        <i class="fa fa-sm fa-user"></i>
                      </template>
                      <template v-slot="subactivities_info">
                        <b-tooltip v-if="subactivities_info.row.manager" :label="subactivities_info.row.manager.fullname">
                          <img :src="subactivities_info.row.manager.avatar"
                              class="w-6 h-6 overflow-hidden rounded-full reduce">
                        </b-tooltip>
                      </template>
                    </b-table-column>

                    <b-table-column field="planning_code" :visible="columns['planning_code'].visible"
                                sortable cell-class="vertical-center">
                      <template v-slot:header="">
                        {{ $t('code') }}
                      </template>
                      <template v-slot="subactivities_info">
                        <div>{{ subactivities_info.row.planning_code }}</div>
                      </template>
                    </b-table-column>

                    <b-table-column field="name" :visible="columns['name'].visible"
                                    cell-class="vertical-center" sortable>
                      <template v-slot:header="">
                        {{ $t('name') }}
                      </template>
                      <template v-slot="subactivities_info">
                        <div :class="subactivities_info.row.access ? 'cursor-pointer' : ''"
                            @click="onRowClicked(subactivities_info.row.is_subactivity, subactivities_info.row.parent_id, subactivities_info.row.id, subactivities_info.row.access)">
                          <activity-time-status :date="subactivities_info.row.current_end_date" class="mr-2 reduce"
                                                :status="subactivities_info.row.status"
                                                v-if="subactivities_info.row.status != 4 && subactivities_info.row.status != 6"/>
                          <b-tooltip v-if="subactivities_info.row.critical_path != null" position="is-right"
                             :label="$t('critical_path')">
                            <b-icon class="fa is-small fa-project-diagram" style="color:#e3342f"></b-icon>
                          </b-tooltip>
                          {{ subactivities_info.row.name }}
                        </div>
                      </template>
                    </b-table-column>

                    <b-table-column field="progress" :visible="columns['progress'].visible"
                                    cell-class="w-32 vertical-center" centered sortable>
                      <template v-slot:header="">
                        {{ progressLabel }}
                      </template>
                      <template v-slot="subactivities_info">
                        <progress-bar
                          :step="subactivities_info.row.progress"
                          color="blue-light"
                          class="w-full"
                        />
                      </template>
                    </b-table-column>

                    <b-table-column v-if="project.can_progress" field="progress_actual" :visible="columns['progress'].visible"
                                    cell-class="w-32 vertical-center" centered sortable>
                      <template v-slot:header="">
                        {{ $t('actual_progress') }}
                      </template>
                      <template v-slot="subactivities_info">
                        <progress-bar
                          :step="subactivities_info.row.progress_actual"
                          color="blue-light"
                          class="w-full"
                        />
                      </template>
                    </b-table-column>

                    <b-table-column field="current_start_date" :visible="columns['current_start_date'].visible"
                                    cell-class="w-25 vertical-center" centered sortable>
                      <template v-slot:header="">
                        {{ $t('start') }}
                      </template>
                      <template v-slot="subactivities_info">
                      {{ subactivities_info.row.current_start_date }}
                      </template>
                    </b-table-column>

                    <b-table-column field="current_end_date" :visible="columns['current_end_date'].visible"
                                    cell-class="w-25 vertical-center" centered sortable>
                      <template v-slot:header="">
                        {{ $t('end') }}
                      </template>
                      <template v-slot="subactivities_info">
                        {{ subactivities_info.row.current_end_date }}
                      </template>
                    </b-table-column>

                    <b-table-column field="status" :visible="columns['status'].visible"
                                    cell-class="w-32 vertical-center" centered sortable>
                      <template v-slot:header="">
                        {{ $t('status') }}
                      </template>
                      <template v-slot="subactivities_info">
                        <activity-status-label :status="subactivities_info.row.status" class="w-full"/>
                      </template>
                    </b-table-column>

                    <b-table-column field="notifications" :visible="columns['notifications'].visible"
                                    cell-class="w-24 vertical-center" centered sortable :custom-sort="sortByNotifications">
                      <template v-slot:header="">
                        <i class="fa fa-bell"></i>
                      </template>
                      <template v-slot="subactivities_info">
                        <entity-notifications
                          :entityId="subactivities_info.row.id"
                          entityType="activity"
                          :mentions="subactivities_info.row.notifications.mentions"
                          :change_status="subactivities_info.row.notifications.change_status"
                          :comments="subactivities_info.row.notifications.comments"
                          :documents="subactivities_info.row.notifications.documents"
                          :assignments_warnings="subactivities_info.row.notifications.assignments_warnings"
                          :enabledChangedStatus="true"
                        />
                      </template>
                    </b-table-column>

                  </template>

                  <template slot="detail" slot-scope="child">
                    <!--Mediciones -->
                    <div class="mb-1" v-if="displayAssignments(child.row)">
                      <div>
                        <h4>
                          <b>{{ $t('measurements') }}</b>
                        </h4>
                      </div>

                      <assignment-table :list="child.row.assignments" :entityId="child.row.id"
                                        :entityBlockedAssignments="child.row.blocked_assignments == 1"
                                        :apiProject="apiProject"
                                        @reload="reloadSubActivities"/>
                    </div>
                  </template>
                </b-table>
              </template>
            </div>
          </template>
        </b-table>

      </template>
    </div>

    <!-- Actualiza la tabla automáticamente, de momento se queda -->
    <!-- <mugen-scroll :handler="fetchData" :should-handle="!isLoadingTable" scroll-container="wrap"></mugen-scroll>
    <b-loading :is-full-page="false" :active.sync="isLoadingTable" :can-cancel="false"></b-loading> -->

  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import {mapGetters} from 'vuex'
import Form from 'vform'
import activityTimeStatus from '·/components/activity-time-status'
import activityStatusLabel from '·/components/activity-status-label'
import assignmentTable from '·/components/assignment-table'
import projectReport from '·/components/project-report'
import projectReportExcel from '·/components/project-report-excel'
import projectReportLotes from '·/components/project-report-lotes'
import moment from 'moment'
import entityNotifications from '·/components/entity-notifications'
import MugenScroll from 'vue-mugen-scroll'
import Buefy from 'buefy'
import BIcon from "buefy/src/components/icon/Icon";
import EventBus from '~/plugins/bus'
import ActivityFilters from '·/components/activity-filters';

Vue.use(moment);
Vue.use(Buefy);

export default {
  middleware: 'auth',
  title: 'activities_page_title',
  subtitle: 'activities_page_subtitle',
  menuOption: '2-2',

  components: {
    BIcon,
    activityTimeStatus,
    activityStatusLabel,
    entityNotifications,
    projectReport,
    projectReportExcel,
    projectReportLotes,
    assignmentTable,
    MugenScroll,
    ActivityFilters
  },

  computed: {

    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
      company: 'app/company',
    }),

    progressLabel() {
      let label = this.$t('progress_label');
      if (this.company.alias == 'welink') {
        label = this.$t('progress_inspected_label');
      }
      return label;
    },

  },

  props: {
    filtersProps: { type: Object, require: true }
  },

  data: () => ({
    form: new Form({
      creator: 'Todos',
      fecha: '1 Enero 2018 - 31 Diciembre 2018',
    }),
    // paginationRows: 10,
    isLoadingTable: true,
    page: 1,
    last: 1,
    perPage: 25,
    currentPage: 1,
    total: 0,
    orderField: "planning_code",
    orderDir: "asc",
    list: [],
    filterSubactivities: false,
    showSubs: false,
    openedSubactivitiesId: 0,
    subactivities: [],
    showFilters: false,
    generatedFilters: {}, // objeto con los filtros generados por el componente 'activity-filters'
    columns: {
      'in_planning': {title: 'in_planning', visible: true},
      'user': {title: 'manager', visible: true},
      'planning_code': {title: 'code', visible: true},
      'name': {title: 'name', visible: true},
      'progress': {title: 'progress', visible: true},
      'current_start_date': {title: 'start_date', visible: true},
      'current_end_date': {title: 'end_date', visible: true},
      'status': {title: 'status', visible: true},
      'status_id': {title: 'custom_status', visible: true},
      'notifications': {title: 'notifications', visible: true},
    },
    showExcelReport: false,
    showLotesReport: false,
    childStatusList: [], // lista status de componente activity-filters
    apiProject: {}, // algunas caracteristicas del proyecto actual devueltas por api (si user es certificador)
  }),

  mounted() {

    // recupera de la localstore la pagina que estamos (util al volver de una validacion al listado)
    this.page = this.$store.getters["searchFilters/activities"].page || 1
    this.last = this.$store.getters["searchFilters/activities"].last || 1

    this.applyFilters(this.page);
    let self = this;

    // Muestra columna "mis estados" cuando el componente hijo 'activity-filters' recibe por api lista statusList
    EventBus.$on("statusListReceived", function (statusList) {
      self.childStatusList = statusList
    });

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshActivitiesNotifications', function (e) {
      self.isLoading = true;
      self.applyFilters(self.page);
    });

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshSubactivitiesNotifications', function (e) {
      self.isLoading = true;
      self.applyFilters(self.page);
    });
  },

  beforeDestroy(){
    EventBus.$off("statusListReceived");
    EventBus.$off("refreshActivitiesNotifications");
    EventBus.$off("refreshSubactivitiesNotifications");
  },

  created() {
    this.showExcelReport = (window.location.hostname.includes('test') ||
      window.location.hostname.includes('ignis') ||
      this.user.company.id == 16)
      ? true : false

    this.showLotesReport = this.company.configs && this.company.configs.loteCanal
  },

  methods: {
    async getActivitiesList(args = []) {

      const {data} = await axios.get('/api/v2/activities', {params: args});
      // Last and total
      this.last = data.lastPage;
      this.total = data.total;
      this.apiProject = data.project;
      // guarda en la localstore la ultima pagina
      this.$store.dispatch('searchFilters/setActivitiesFilters', { last: this.last });

      if (data && data.activities) {
        //deducimos si no tiene status kanban aplicados y los ocultamos
        if (!this.hasStatusList())
          delete this.columns.status_id;

        this.list = this.activitiesRemapping(data.activities);
      } else {
        this.$notify.error('error_loading_activities')
      }

      this.isLoadingTable = false
    },

    /**
     * Remapea la actividad para esta tabla
     */
    activitiesRemapping(activities) {
      for (let a in activities) {
        if (activities[a].assignments && activities[a].assignments.length > 0) {
          activities[a].alert = 0;
          for (let i in activities[a].assignments) {
            let number = this.getAssignmentExecutionStatus(activities[a].assignments[i]);
            activities[a].assignments[i].execution_status = number;

            if (activities[a].alert !== number && activities[a].alert !== 1)
              activities[a].alert = number;

          }
        }
      }
      return activities;
    },

    filtersUpdated() {
      this.isLoadingTable = true;
      this.applyFilters(1);
    },

    customizeFilters() {
      // let filters = this.$refs.filtersRef.generateFilters();
      let filters = this.filtersProps;
      filters.with_assignments = 1;
      filters.order_field = this.orderField;
      filters.order_dir = this.orderDir;
      filters.per_page = this.perPage;
      //filters.s = 1; //con subactividades. Como se piden de todas formas al desplegar, se quitan de aqui para aligerar carga

      if (this.filterSubactivities) {
        filters.filter_subactivities = 1
      }

      return filters;
    },

    onRowClicked(isSubactivity, activityId, subactivityId, access = true) {
      if (access) {
        if (!isSubactivity) {
          this.$router.push({name: 'activity.home', params: {activity_id: activityId}})
        } else {
          this.$router.push({
            name: 'subactivity.home',
            params: {activity_id: activityId, subactivity_id: subactivityId}
          })
        }
      }
    },

    onCreateActivityClicked() {
      let url = '/api/v2/locations?all=1&p=' + this.project.id;
      self = this;
      axios.get(url)
        .then(function (response) {
          if (response.data && response.data.success && response.data.locations) {
            self.$router.push({name: 'activity.create'})
          } else {
            self.$notify.error('project_without_locations_create_activity')
          }
        });
    },

    displayKanban() {
      this.$router.push({name: 'project.activities.kanban'});
    },

    displayGantt() {
      this.$router.push({name: 'project.activities.gantt'});
    },

    async addFavorite(activityId, index, isSubactivity = false) {
      let url = '/api/v2/user/' + activityId + '/favorite/add';
      const {data} = await axios.post(url);
      if (data && data.success) {
        //refrescamos la tabla
        if (isSubactivity) {
          // Recorremos las subactividades de la actividad abierta y buscamos la subactividad para actualizarla
          // No podemos utilizar el index ya que si las subactividades han sido reordenadas éste se queda desfasado
          let i = 0;
          let found = false;
          while (!found && i <= this.subactivities[this.openedSubactivitiesId].length) {
            if (this.subactivities[this.openedSubactivitiesId][i].id === activityId) {
              this.subactivities[this.openedSubactivitiesId][i].favorite = true;
              found = true
            }
            i++
          }

        } else {
          this.list[index].favorite = true;
        }

      } else {
        this.$notify.error('error_add_favorite')
      }
    },

    async deleteFavorite(activityId, index, isSubactivity = false) {
      let url = '/api/v2/user/' + activityId + '/favorite/delete';
      const {data} = await axios.post(url);
      if (data && data.success) {
        //refrescamos la tabla
        if (isSubactivity) {
          // Recorremos las subactividades de la actividad abierta y buscamos la subactividad para actualizarla
          // No podemos utilizar el index ya que si las subactividades han sido reordenadas éste se queda desfasado
          let i = 0;
          let found = false;
          while (!found && i <= this.subactivities[this.openedSubactivitiesId].length) {
            if (this.subactivities[this.openedSubactivitiesId][i].id === activityId) {
              this.subactivities[this.openedSubactivitiesId][i].favorite = false
              found = true
            }
            i++
          }
        } else {
          this.list[index].favorite = false;
        }
      } else {
        this.$notify.error('error_add_favorite')
      }
    },

    async createProjectDossier() {
      //TO-DO: realizar llamada al generador de informes para el proyecto this.project.id
      return true
    },

    showSubactivities(activityId) {
      this.showSubs = (!this.showSubs);
    },

    applyFilters(page) {
      this.generatedFilters = this.customizeFilters();
      this.generatedFilters.page = (page <= this.last) ? page : 1;
      // actualiza en localstore la pagina actual
      this.$store.dispatch('searchFilters/setActivitiesFilters', { page: this.generatedFilters.page });
      this.currentPage = this.generatedFilters.page;
      this.getActivitiesList(this.generatedFilters)
    },

    fetchData() {
      if (this.page <= this.last) {
        this.isLoadingTable = true;
        this.applyFilters(this.page);
        this.isLoadingTable = false
      }
    },

    onPageChange(page) {
      // guarda en la localstore la pagina que estamos
      this.$store.dispatch('searchFilters/setActivitiesFilters', { page: page });
      this.openedSubactivitiesId = 0;
      this.page = page;
      this.fetchData()
    },

    onSort(field, order) {
      this.openedSubactivitiesId = 0;
      this.orderField = field;
      this.orderDir = order;
      this.fetchData()
    },

    displaySubactivities(row) {
      return !row.isSubactivity && row.subactivities_num > 0
    },

    displayAssignments(row) {
      return (row.assignments && row.assignments.length > 0);
    },

    displaySubactivitiesOrAssignments(row) {
      return !!(this.displayAssignments(row) || this.displaySubactivities(row));
    },

    openDetails(row, index) {
      if (typeof this.subactivities[row.id] === 'undefined') {
        this.isLoadingTable = true;
        let self = this;
        let url = '/api/v2/activity/' + row.id + '/subactivities';
        let params = {with_assignments: 1};
        axios.get(url, {params: params}).then(function (response) {
          if (response.data && response.data.success) {
            self.subactivities[row.id] = response.data.subactivities
          }
          self.isLoadingTable = false;
          self.openedSubactivitiesId = row.id
        })
      } else {
        self.isLoadingTable = false;
        self.openedSubactivitiesId = row.id
      }
    },

    getSubactivities(row) {
      return this.subactivities[row.id]
    },

    getRowClass(row, index) {
      // (row, index) => ! row.parent_access ? '' : 'is-hidden'
      let cssClass = '';
      if (row.parent_access && !this.filterSubactivities) {
        cssClass = 'is-hidden'
      } else if (row.subactivities_notifications) {
        cssClass = cssClass + ' sub-notifications'
      }

      return cssClass
    },

    checkFilterSubactivities() {
      this.filterSubactivities = !this.filterSubactivities;
      this.isLoadingTable = true;
      this.applyFilters(1)
    },

    sortByCode() {
      //ya viene asi de backend
    },

    sortByNotifications(a, b, isAsc) {
      return isAsc ?
        a.notifications.total - b.notifications.total :
        b.notifications.total - a.notifications.total
    },

    sortByFavorite(a, b, isAsc) {
      return isAsc ?
        (+a.favorite) - (+b.favorite) :
        (+b.favorite) - (+a.favorite)
    },

    sortByShowOnDevices() {

    },

    hasStatusList() {
      return this.childStatusList.length > 0;
      // return this.$refs.filtersRef.hasStatusList(); // da error por componente todavia no montado (ahora usamos emit-on)
    },

    getStatusIdName(id) {
      let status = this.$refs.filtersRef.statusList.find(b => b.id == id);

      if (status)
        return status.name;

      return '';
    },

    getAssignmentStatusColor(row) {
      switch (row.alert) {
        case -1:
          return 'bg-blue';

        case 0:
          return 'bg-green';

        case 1:
          return 'bg-red';

        default:
          return 'bg-blue';
      }
    },

    getAssignmentStatusLabel(row) {
      switch (row.alert) {
        case -1:
          return 'assignments-under';

        case 0:
          return 'assignments-completed';

        case 1:
          return 'assignments-over';
      }
    },

    /**
     * Calcula el estado de la medición
     * @param row
     * @returns {number}
     */
    getAssignmentExecutionStatus(row) {
      if (row.type === 'task')
        if (row.completed)
          return 0; // perfecto
        else return -1;// por debajo, tarea aun no completada

      if (row.executed_quantity < row.total)
        return -1; // por debajo
      else if (row.executed_quantity > row.total)
        return 1; // por encima
      else return 0; // cantidad exacta
    },

    reloadActivities(data) {
      this.applyFilters(this.page);
    },

    reloadSubActivities(data) {
      this.applyFilters(this.page);
    },

    async showOnDevice(activityId, index, isSubactivity = false) {
      if( !this.project.can_admin)
        return;
      let url = '/api/v2/activity/' + activityId + '/showOnDevices';
      const {data} = await axios.post(url, {'show' : 1});
      // const {data} = await axios.post(url, {params: {'show' : 1}});
      if (data && data.success) {
        //refrescamos la tabla
        if (isSubactivity) {
          // Recorremos las subactividades de la actividad abierta y buscamos la subactividad para actualizarla
          // No podemos utilizar el index ya que si las subactividades han sido reordenadas éste se queda desfasado
          let i = 0;
          let found = false;
          while (!found && i <= this.subactivities[this.openedSubactivitiesId].length) {
            if (this.subactivities[this.openedSubactivitiesId][i].id === activityId) {
              this.subactivities[this.openedSubactivitiesId][i].show_on_devices = true;
              found = true
            }
            i++
          }

        } else {
          this.list[index].show_on_devices = true;
          if( this.subactivities[this.openedSubactivitiesId] )
            this.subactivities[this.openedSubactivitiesId].forEach( function(item, indice, array) {
              item.show_on_devices = true
            });
        }

      } else {
        this.$notify.error('error_add_favorite')
      }
    },

    async hideOnDevice(activityId, index, isSubactivity = false) {
      if( !this.project.can_admin)
        return;
      let url = '/api/v2/activity/' + activityId + '/showOnDevices';
      const {data} = await axios.post(url, {'show' : 0});
      if (data && data.success) {
        //refrescamos la tabla
        if (isSubactivity) {
          // Recorremos las subactividades de la actividad abierta y buscamos la subactividad para actualizarla
          // No podemos utilizar el index ya que si las subactividades han sido reordenadas éste se queda desfasado
          let i = 0;
          let found = false;
          while (!found && i <= this.subactivities[this.openedSubactivitiesId].length) {
            if (this.subactivities[this.openedSubactivitiesId][i].id === activityId) {
              this.subactivities[this.openedSubactivitiesId][i].show_on_devices = false
              found = true
            }
            i++
          }
        } else {
          this.list[index].show_on_devices = false;
          if( this.subactivities[this.openedSubactivitiesId] )
            this.subactivities[this.openedSubactivitiesId].forEach( function(item, indice, array) {
              item.show_on_devices = false
            });
        }
      } else {
        this.$notify.error('error_add_favorite')
      }
    },

    goToPage(destiny = 'drawings') {

      switch (destiny) {

        case 'drawings':
            this.$router.push({ name: 'project.drawings' })
            break;
        case 'groups': // ver la vista de actividades agrupadas
            this.$router.push({ name: 'project.activities.groups' })
            break;
      }

    },

    colorGroup(group){
      let defaultColor = "#F29B57"
      let configs = JSON.parse(group.configs)

      return configs && configs.color ? configs.color : defaultColor
    },

    nameGroup(group){
      return group.name.substring(0, 2).toUpperCase()
    }

  },

};
</script>

<style lang="scss">
.disabled-subactivity {
  background: #F8FAFC !important;
  color: #B8C2CA !important;
}

.reduce {
  transform: scale(0.80)
}

.vertical-center {
  vertical-align: inherit !important;
}

/* Se le debería de poder cambiar el icono al botón de details, no probado */
.sub-notifications > td.chevron-cell {
  background-color: #F8E0E0 !important;
}

.sub-notifications > td.chevron-cell svg {
  color: #e3342f !important;
}

.subactivities {
  .table th {
    background-color: #89a4d6;
  }

  &.b-table .table.is-bordered th.is-current-sort, &.b-table .table.is-bordered th.is-sortable:hover {
    background-color: #89a4d6 !important;
  }
}
</style>


