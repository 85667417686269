  <template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="() => { showFormImportEntities = true }"
        icon="file-import"
        :disabled="showFormData || showFormLinkGroup"
      >{{ $t('import_groups') }}</v-button>

      <v-button
        @click="addGroup"
        icon="plus"
        class="ml-4"
        :disabled="showFormImportEntities || showFormLinkGroup"
      >{{ $t('add_group') }}</v-button>
    </div>

    <div>
      <b-switch v-model="subactivities_progress_groups"
        :rounded="true"
        :outlined="false"
        size="is-small"
        type="is-success"
        passive-type='is-info'
        :left-label='true'
        @input="setSubactivitiesProgressGroups()"></b-switch>
      <label class="font-bold">{{subactivities_progress_groups ? 'Subactividades': 'Actividades'}} dan progreso a grupos</label>

      <div class="relative ml-2 cursor-pointer group" @click="showModal = true">
        <img class="w-16 h-16 object-contain group-hover:opacity-90 transition-opacity" alt="Toggle Image"
          :src="asset(imageSrc)"
        />
        <div class="absolute inset-0 flex items-center justify-center bg-gray-400 bg-opacity-50 backdrop-blur-sm opacity-0 group-hover:opacity-100 transition-opacity">
          <i class="fas fa-search text-white "></i>
        </div>
      </div>
    </div>

    <b-modal v-model="showModal" title="Imagen Expandida" hide-footer>
      <img :src="asset(imageSrc)" alt="Expanded Image" class="w-full" />
    </b-modal>

    <div class="flex">
      <div :class="[( showFormData || showFormImportEntities || showFormLinkGroup ? 'w-7/12' : 'w-full'), 'mr-2']">
        <activity-groups-list ref="list_groups"
          :entity-type="entityType"
          :entity-id="projectId"
          :disabled-menu-actions="showFormImportEntities || showFormData || showFormLinkGroup"
          @add-group="handleAddGroup"
          @edit-group="handleEditGroup"
          @link-group="handleLinkGroup"
          @drop-group="handleDropGroup"
        ></activity-groups-list>
      </div>

      <transition name="fade">
        <div class="w-5/12 ml-2" v-if="showFormData">
          <activity-group-edit
            :entity-type="entityType"
            :entity-id="projectId"
            :group="group"
            @epc-close="handleOnCloseEdit"
            @epc-success-creating="handleSuccess"
          />
        </div>

        <div class="w-5/12 ml-2" v-if="showFormLinkGroup">
          <activity-group-link
            :project-id="projectId"
            :group="group"
            :entity-type="entityType"
            @epc-close="handleOnCloseLinkGroup"
          />
        </div>

        <div class="w-5/12 ml-2" v-if="showFormImportEntities">
          <activity-groups-import
            :projectId="projectId"
            :entity-type="'activity-groups'"
            :morph-type="entityType"
            @epc-close="(event) => { showFormImportEntities = false }"
            @epc-success-import="() => { showFormImportEntities = false ; $refs.list_groups.reload() }"
          />
        </div>

      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import ActivityGroupsList from './activity-groups-list.vue';
import ActivityGroupEdit from './activity-group-edit.vue';
import ActivityGroupLink from './activity-group-link.vue';
import ActivityGroupsImport from './activity-groups-import.vue';

export default {
  name: "manage-groups-activities",

  components: {
    ActivityGroupsList,
    ActivityGroupEdit,
    ActivityGroupLink,
    ActivityGroupsImport,
  },

  data: () => ({
    group: null, // objeto que se crea
    showFormData: false,
    showFormImportEntities: false,
    showFormLinkGroup: false,
    groups: [], // unidades de la compania
    subactivities_progress_groups: false,
    showModal: false,
  }),

  props: {
    projectId: { type: Number, required: true },
    entityType: { type: String, required: true } // el entityType para el componente activity groups import pasa a ser el morphType
  },

  computed: {
    imageSrc() {
      return this.subactivities_progress_groups
        ? 'public/progress-group-subactivity.png'
        : 'public/progress-group-activity.png';
    }
  },

  created() {
    this.getGroups()
    this.getProject()
  },

  mounted() {
  },

  methods: {
    async getGroups() {
      this.groups = []

      const {data} = await axios.get('/api/v2/project/'+ this.projectId + '/activity/groups', {params: {}})
      if (data && data.success) {
        this.groups = data.groups
      }
      // else
      //   error_retreving_group_info
    },

    async getProject() {
      const {data} = await axios.get('/api/v2/project/'+ this.projectId)
      if (data) {
        this.subactivities_progress_groups = !!data.subactivities_progress_groups
      }
    },

    async setSubactivitiesProgressGroups() {
      await axios.post("/api/v2/project/"+this.projectId+"/set-subactivities-progress-groups", {
        subactivities_progress_groups: this.subactivities_progress_groups
      });
    },

    addGroup() {
      this.group = null
      this.showFormData = true;
    },


    handleOnCloseEdit(event) {
      this.showFormData = !event
    },

    handleOnCloseLinkGroup(event) {
      this.showFormLinkGroup = !event
    },

    handleSuccess(entity) { // recibe la entidad editada
      this.showFormData = false
      this.$refs.list_groups.reload()
    },

    // handleEditEntity(entity) {
    //   this.entity = entity
    //   this.showFormData = true;
    // },

    handleAddGroup(group) {
      this.group = group

      this.showFormData = true;
    },

    handleEditGroup(group) {
      this.group = group
      this.showFormData = true;
    },

    handleLinkGroup(group) {
      this.group = group
      this.showFormLinkGroup = true
    },

    async handleDropGroup(group) {
      let form = new Form({
        id: group.id,
        entity_type: this.entityType,
        parent_id: group.parent_id === group.id ? null : group.parent_id,
        code: group.code,
        name: group.name,
        weight: group.weight,
        color: group.color,
      })

      let url = `/api/v2/activity/groups/${group.id}`; // se edita el grupo

      const { data } = await form.post(url).catch((error) => { console.log(error) })

      if (data && data.success) {

        this.$notify.success('success_createing_group')

        if( data.warning )
          this.$notify.warning(data.warning)
        this.handleSuccess();
      } else {
        this.processingData = false
        if( data && data.message )
          this.$notify.error(data.message)
        else
          this.$notify.error('error_creating_group')
      }
    },
  }

};
</script>

<style scoped>
</style>
