<template>
<div class="flex-col">

  <div class="w-full flex justify-between below-top-bar">

    <div class="flex">
      <router-link :to="{ name: 'project.dashboard.sanea' }" :class="['btn', 'btn-blue', 'px-4', 'rounded', 'py-2']">&lt; {{ $t('back' )}} a dashboard general </router-link>
    </div>

    <!-- <b-tooltip :label="txtTooltipExport || this.$t('export')" position="is-left">
      <div class="rounded-full bg-blue w-8 h-8 flex items-center justify-center text-grey-lightest cursor-pointer" @click="exportReport">
        <b-icon icon="download" pack="fas" size="is-small" v-if="! isLoadingExport"/>
        <b-icon pack="fas" icon="sync-alt" custom-class="fa-spin" v-if="isLoadingExport"></b-icon>
      </div>
    </b-tooltip> -->
  </div>



  <div class="">

    <div class="text-blue font-bold mt-4">{{ panel1.dsoName }}</div>

    <!-- Panelitos -->
    <div class="flex flex-row">

      <!-- Panel 1 -->
      <div class="panel flex flex-col w-1/2">
        <!-- <label class="text-blue font-bold">Avance del presupuesto</label>-->
        <label class="text-blue italic">Suma PEM Fichas:</label> {{ panel1.sumaPem }}<br>
        <label class="text-blue italic">Presupuesto LC Inicial:</label> {{ panel1.presLcInicial }}<br>
        <label class="text-blue italic">Presupuesto LC Vigente:</label> {{ panel1.presLcVigente }}<br>
        <label class="text-blue italic">PEC del DSO ejecutado actualmente:</label> <span v-if="panel1.avancePec">{{ panel1.avancePecSinFormula }}</span><br>
        <label class="text-blue italic">LC Ejecutado (con retención):</label> <span v-if="panel1.avancePec">{{ calculate('lcEjecutado', panel1) }}</span><br>
        <label class="text-blue italic">Porcentaje avance acreditado (sin retención):</label><span v-if="panel1.avancePec">{{ calculate('avancePecPercent', panel1) }}%</span><br>
        <label class="text-blue italic">Acreditado: <b-tooltip :label="'PEC * 1,19 * (1-Baja)'" position="is-right"><b-icon pack="fas" icon="circle-info" /></b-tooltip></label> <span v-if="panel1.avancePec">{{ panel1.avancePec }}</span><br>
      </div>

      <!-- Panel 2 -->
      <div class="panel flex flex-col w-1/2 ml-2">
        <label class="text-blue font-bold">Metros de colector</label>
        <label class="text-blue italic">Total en DSO:</label> {{ this.panel2.total }}<br>
        <label class="text-blue italic">Ejecutado:</label> {{ this.panel2.executed }}<br>
        <label class="text-blue italic">Porcentaje de avance:</label> {{ panel2.percentage }}%
      </div>

    </div>

    <!-- Panel 3 -->
    <div class="panel w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">Comparación Acreditado vs Planificación Inicial Acreditado</div>
      <div class="flex flex-row w-full"><line-chart :data="panel3" :suffix="'€'" /></div>
    </div>

    <!-- Panel 4 -->
    <div class="panel w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">Presupuesto Acreditado por Actuación</div>
      <div class="flex flex-row w-full"><column-chart :data="panel4" :suffix="'€'" /></div>
    </div>

    <!-- Panel 5 -->
    <div class="panel w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">% de Avance Acreditado por Actuación</div>
      <div class="flex flex-row w-full"><line-chart :data="panel5" :suffix="'%'" /></div>
    </div>

    <!-- Panel 6 -->
    <div class="panel w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">Avance Acreditado por actuación</div>
      <div class="flex flex-row w-full"><line-chart :data="panel6" :suffix="'€'" /></div>
    </div>

    <div class="flex flex-row">
      <!-- Panel 7 -->
      <div class="panel flex flex-col w-1/2">
        <label class="text-blue font-bold">Ml colectores certificados por diametro</label>
        <pie-chart suffix="" :data="panel7"/>

        <table class="mx-5 mt-4">
          <tbody>
            <tr class="panel" v-for="(line, key, index) in panel7" :key="'7_'+index">
              <td align="center" class="justify-start py-2 w-2/12 bg-grey-lightest">
                <div v-if="piechartColors[index]" :style="'width: 20px; border-radius: 1em; height: 20px; background:' + piechartColors[index]"></div>
              </td>
              <td align="center" class="justify-start py-2 w-5/12 bg-indigo-lightest">
                <span class="text-sm font-semibold text-blue">{{ key }}</span>
              </td>
              <td align="center" class="justify-start py-2 w-5/12 bg-grey-lightest">
                <span class="text-sm font-semibold">{{ line }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Panel 8 -->
      <div class="panel flex flex-col w-1/2 ml-2">
        <label class="text-blue font-bold">Materiales</label>
        <pie-chart suffix="" :data="panel8" />

        <table class="mx-5 mt-4">
          <tbody>
            <tr class="panel" v-for="(line, key, index) in panel8" :key="'8_'+index">
              <td align="center" class="justify-start py-2 w-2/12 bg-grey-lightest">
                <div v-if="piechartColors[index]" :style="'width: 20px; border-radius: 1em; height: 20px; background:' + piechartColors[index]"></div>
              </td>
              <td align="center" class="justify-start py-2 w-5/12 bg-indigo-lightest">
                <span class="text-sm font-semibold text-blue">{{ key }}</span>
              </td>
              <td align="center" class="justify-start py-2 w-5/12 bg-grey-lightest">
                <span class="text-sm font-semibold">{{ line }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Panel 9 -->
    <div class="w-full mt-8">
      <div class="flex flex-row w-full text-blue font-bold">ml de colector certificados</div>
      <div class="flex flex-row w-full"><line-chart :suffix="'ml'" :data="panel9" :colors="['#d12411']" /></div>
    </div>


  </div>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ScaleLoader,
  },

  data: () => ({
    activityId: 0,
    panel1: {},
    panel2: [],
    panel3: [],
    panel4: [],
    panel5: [],
    panel6: null,
    panel7: null,
    panel8: null,
    panel9: null,
    piechartColors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#3b3eac', '#0099c6', '#dd4477', '#66aa00', '#b82e2e']
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    }),
  },

  mounted() {

    if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.activityId = this.$route.params.activity_id
      this.getPanelsInfo();

      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {


    async getPanelsInfo() {
      await this.getPanel12(); // tras terminar este, se lanzan los siguientes. Importante porque otras llamadas posteriores modifican paneles 1 y 2. Como esta llamada es mas lenta que las siguientes, debe estar ya cargado para no sobreescribir la respuesta de las otras
      this.getPanel3();
      this.getPanel456();
      this.getPanel78();
      this.getPanel9();
    },


    async getPanel12() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneadsopanel12';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel1 = response.data.data.panel1
          self.panel2 = response.data.data.panel2
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel12")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel3() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneadsopanel3';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel3 = response.data.data.panel3
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel3")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel456() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneadsopanel456';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel4 = response.data.data.panel4
          self.panel5 = response.data.data.panel5
          self.panel6 = response.data.data.panel6

          // Aqui calculamos el avancePEC del panel1
          // tomamos el panel4[1] con los datos de las cantidades ejecutadas
          let sumExecutedInterventions = 0;
          Object.keys(self.panel4[1].data).forEach((intervention) => {
            // como esta agrupado por actuaciones, vamos sumando cada una
            sumExecutedInterventions = sumExecutedInterventions + self.panel4[1].data[intervention]
          })

          // tomamos el panel4[0] con los datos de las cantidades proyectadas
          let sumTotalInterventions = 0;
          Object.keys(self.panel4[0].data).forEach((intervention) => {
            // como esta agrupado por actuaciones, vamos sumando cada una
            sumTotalInterventions = sumTotalInterventions + self.panel4[0].data[intervention]
          })

          self.panel1.avancePec = sumExecutedInterventions
          // como las ejecuciones ya vienen con la formula añadida, solo necesitamos dividir entre la formula para tener el avance sin formula
          self.panel1.avancePecSinFormula = parseFloat(self.panel1.avancePec) / (1.19 * (1 - parseFloat(self.panel1.baja)))
          self.panel1.avancePecRaw = self.panel1.avancePec // util para algunos calculos posteriores sin formatear
          self.panel1.avancePecSinFormulaRaw = self.panel1.avancePecSinFormula // util para algunos calculos posteriores sin formatear
          // formateado a moneda
          self.panel1.avancePec = Intl.NumberFormat(self.user.locale, {style: "currency", currency: "EUR"} ).format(self.panel1.avancePec)
          self.panel1.avancePecSinFormula = Intl.NumberFormat(self.user.locale, {style: "currency", currency: "EUR"} ).format(self.panel1.avancePecSinFormula)
          self.panel1.totalPec = sumTotalInterventions
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel456")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel78() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneadsopanel78';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel7 = response.data.data.panel7
          self.panel8 = response.data.data.panel8
          
          self.panel2.total = response.data.data.panel2aux.total
          self.panel2.executed = response.data.data.panel2aux.executed
          self.panel2.percentage = parseFloat((self.panel2.executed * 100) / self.panel2.total).toFixed(2)
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel78")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel9() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/saneadsopanel9';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel9 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel9")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    calculate(key, data) {
      let result = 0;
      switch (key) {
        case 'lcEjecutado':
          // (PEC * 1,19 * (1-Baja)) * 0,9 + Devolución de Retenciones (Campo Extra DSO)
          let devolucionRetenciones = 0 // futuro campo extra DSO, que se debe recibir de backend via panel1
          result = (parseFloat(data.avancePecSinFormulaRaw) * 1.19 * (1 - parseFloat(data.baja))) * 0.9 + devolucionRetenciones
          result = Intl.NumberFormat(this.user.locale, {style: "currency", currency: "EUR"} ).format(result)
          break;

        case 'avancePecPercent':
          result = parseFloat( (data.avancePecRaw * 100) / data.totalPec).toFixed(2)
          break;

        case 'acreditado':
          // AvancePec ya viene de backend con la formula aplicada, asi que podemos ignorar este calculo
          // Si AvancePec viniera sin formula, procederiamos asi
          // PEC * 1,19 * (1-Baja)
          // result = (parseFloat(data.avancePecRaw) * 1.19 * (1 - parseFloat(data.baja)))
          // result = Intl.NumberFormat(this.user.locale, {style: "currency", currency: "EUR"} ).format(result)
          // break;

      }
      return result
    },


  },

};
</script>

<style scoped>
.column-scroll {
  width: 50%;
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  margin-right: 0.5rem;
}
.column-scroll:last-child {
  margin-left: 0.5rem;
}
</style>
