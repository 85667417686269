<template>
  <div>
    <!-- Tabla de cost budget -->
    <div class="panel">
      <b-table :data="budgets" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage" :total="total"
        :options="perPageOptions" @page-change="onPageChange" backend-sorting :default-sort="orderField"
        :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas" scrollable
        detailed detail-key="assignments" :show-detail-icon="showDetailIcon">

        <template>
          <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column field="name" :label="$t('breakdown_name')" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="name" :label="$t('ud')" v-slot="props">
            {{ props.row.unit ? props.row.unit.name : '' }}
          </b-table-column>
          <b-table-column field="provider" :label="$t('provider')" sortable v-slot="props">
            {{ props.row.provider ? props.row.provider.name : 'N/A' }}
          </b-table-column>
          <b-table-column field="unit_price" :label="$t('unit_price')" sortable v-slot="props" class="text-center">
            {{ formatNumber(props.row.unit_price) }}
          </b-table-column>
          <b-table-column field="quantity" :label="$t('quantity')" sortable v-slot="props" class="text-center">
            {{ formatNumber(props.row.quantity) }}
          </b-table-column>
          <b-table-column field="coefficient" :label="$t('coefficient')" sortable v-slot="props" class="text-center">
            {{ formatNumber(props.row.coefficient) }}
          </b-table-column>
          <b-table-column field="total_amount" :label="$t('total_price')" sortable v-slot="props" class="text-center">
            {{ formatNumber(formatPrice(props.row.unit_price, props.row.quantity)*props.row.coefficient) }}
          </b-table-column>
          <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
            <div class="w-full h-full flex items-center justify-around">
              <b-tooltip :label="$t('edit_breakdown')" position="is-left">
                <div @click="editButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="pencil-alt" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('delete_breakdown')" position="is-left" type="is-danger">
                <div @click="removeResources(props.row)" class="cursor-pointer flex text-red">
                  <b-icon pack="fas" icon="trash-alt" />
                </div>
              </b-tooltip>
            </div>
          </b-table-column>
        </template>
        <template #detail="props">
          <div v-if="props.row.assignments && props.row.assignments.length > 0">
            <b-table :data="props.row.assignments" :loading="isLoadingTable" class="w-full" striped bordered
              icon-pack="fas" scrollable>
              <b-table-column field="assignment_name" :label="$t('assignment')" sortable v-slot="props"
                class="text-center">
                <div>
                  {{ props.row.assignment_name }}
                </div>
                <div class="text-xs">
                  {{ props.row.parent.subchapter ? props.row.parent.code + ' > ' + props.row.parent.subchapter.code :
                    props.row.parent.code }}
                </div>
              </b-table-column>
              <b-table-column field="quantity" :label="$t('quantity')" sortable v-slot="props" class="text-center">
                {{ formatNumber(props.row.quantity) }}
              </b-table-column>
              <b-table-column field="coefficient" :label="$t('coefficient')" sortable v-slot="props"
                class="text-center">
                {{ formatNumber(props.row.coefficient) }}
              </b-table-column>
            </b-table>
          </div>
          <div v-else class="text-center py-4">
            {{ $t('no_results') }}
          </div>
        </template>

        <template #empty>
          <div class="has-text-centered">{{ $t('no_results') }}</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios'

import { parseQuantity } from './helpers.js'

export default {
  props: {
    projectId: { type: Number, require: true },
  },

  computed: {
    ...mapGetters({
      company: "app/company",
    }),

    filters() {
      let filters = {
        'order_field': this.orderField,
        'order_dir': this.orderDir,
        'perPage': this.perPage,
        'page': this.page,
        'project_id': this.projectId
      }
      return filters
    }
  },

  data: function () {
    return {
      isLoadingTable: true,
      budgets: [],
      perPage: 25,
      orderField: 'code',
      orderDir: 'asc',
      page: 1,
      last: 1,
      total: 0,
      applyFilters: {},
      perPageOptions: [5, 10, 20, 50, 100],
      showDetailIcon: true,
    }
  },

  created() {
    this.getBudgets()
  },

  methods: {
    async getBudgets(filters = null) {

      this.isLoadingTable = true
      this.budgets = []
      const params = filters ? filters : this.filters;
      const { data } = await axios.get(`/api/v2/cost-budgets`, { params });
      if (data && data.success) {
        this.budgets = data.budgets;
        this.last = data.pagination.lastPage;
        this.total = data.pagination.total;
        this.isLoadingTable = false
      }
    },

    onSort(field, order) {
      this.companyId = this.company.id
      this.orderField = field
      this.orderDir = order
      this.getBudgets();
    },

    onPageChange(page) {
      this.page = page
      this.getBudgets();
    },

    reload(arg = {}) {
      let filters = { ...arg, ...this.filters }
      this.getBudgets(filters);
    },

    async editButtonClicked(entity) {
      this.$emit('epc-edit', entity)
    },

    async removeResources(resource) {
      try {
        this.$dialog.confirm({
          title: this.$t('delete_breakdown'),
          message: this.$t('delete_activity_confirm_text', [resource.name]),
          confirmText: this.$t('remove_resource'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            this.processingData = true;
            await axios.delete(`/api/v2/cost-budget/${resource.id}`);
            this.$buefy.toast.open({
              message: this.$t("removed_successfully"),
              type: 'is-success'
            });
            this.$emit('reload-list')
          },
          onCancel: () => {
            this.processingData = false;
          }
        });
      } catch (error) {
        this.$buefy.toast.open({
          message: this.$t("remove_error") + (error.response.data.message),
          type: 'is-danger'
        });
      } finally {
        this.processingData = false;
      }
    },

    archiveClass(value) {
      return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
    },

    handleSelect(newRow) {
      this.$emit('update:template', newRow);
    },

    clearSelected() {
      this.rowSelected = null
    },

    showQuantity(quantity) {
      return parseQuantity(quantity);
    },
    formatPrice(unitPrice, quantity) {
      if (!unitPrice || !quantity) return '0.00';
      const total = unitPrice * quantity;
      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total);
    },
    formatNumber(value) {
      const number = parseFloat(value);
      if (isNaN(number)) return '0,00';
      let [integerPart, decimalPart] = number.toFixed(2).split('.');
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return `${integerPart},${decimalPart}`;
    }
  },

};
</script>
