<template>
    <section>
        <div class="mt-4">
            <div class="flex mr-4 text-blue cursor-pointer" @click="downloadTemplate">
                <b-icon pack="fas" icon="file-excel"></b-icon>
                {{ $t('download_template') }}
            </div>

          <!-- modal descarga de plantilla -->
          <b-modal v-model="downloadModal" :width="300">
            <div class="flex flex-col bg-white p-4 rounded-lg" >
              <div class="flex items center">
                <span class="text-blue md:text-lg font-bold">{{$t('select_template')}}</span>
              </div>
              <div class="border-b border-gray-300 mt-4"></div>
              <div class="flex flex-col mt-4" v-for="(file, index) in files" :key="index">
                <div class="flex items-center cursor-pointer mt-2" 
                  @click="downloadTemplateByName(file.filename)">
                  <b-icon pack="fas" icon="file-excel" class="mr-2 text-blue"></b-icon>
                  <span class="text-blue"
                  >{{ $t(file.name) }}</span>
                </div>
              </div>
            </div>
          </b-modal>

            <div class="panel">
                <form @submit.prevent="submitForm" :files="true">
                    <div class="flex w-full">
                        <div class="flex">
                            <b-field class="flex mr-4">
                                <b-upload v-model="form.excelFile" @change="cleanErrors" required
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                    <a class="button is-primary">
                                        <b-icon pack="fas" icon="upload"></b-icon>
                                        <span>{{ $t('select_file') }}</span>
                                    </a>
                                </b-upload>
                                <span class="file-name" v-if="form.excelFile">{{ form.excelFile.name }}</span>
                            </b-field>
                        </div>
                        <div class="flex" >
                            <b-field class="mr-2">
                                <b-select v-model="form.type" size="is-small" rounded icon="file"
                                    :required="true" @input="resetValidation()">
                                    <option v-for="type in types" :value="type.value">{{$t(type.name)}}</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="flex" v-if="overwriteEnabled">
                            <v-switch v-model="form.overwrite" ref="form_overwrite" :label="$t('allow_to_overwrite')"
                                :color="form.overwrite ? 'green' : 'red'" class="mr-1"
                                v-on:checked="(value) => {
                                    form.overwrite = value
                                    resetValidation()
                                }" />
                        </div>
                        <div class="flex ml-4">
                            <slot name="descripcion"></slot>
                        </div>
                    </div>
                    <div class="flex w-full mt-4">
                        <div class="flex w-full">
                            <b-field class="w-full">
                                <b-input required :placeholder="$t('description')" v-model="form.description"
                                    maxlength="255" expanded></b-input>
                            </b-field>
                        </div>
                    </div>

                    <!-- fragmento provisional. Lotes canal cargas anteriores -->
                    <div class="flex w-full mt-4 mb-0" v-if="form.type == 'task'">
                        <div class="flex w-full">
                            (Fecha efectiva: Solo admin Epc-tracker)
                        </div>
                        <div class="flex w-full">
                            <b-field class="w-full">
                                <b-datepicker icon="calendar" icon-pack="fas" type="month" v-model="effectiveDate" :placeholder="'fecha efectiva'"
                                    :month-names="$t('months_names')" :day-names="$t('days_names')"
                                    :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false" :max-date="previousMonth">
                                </b-datepicker>
                            </b-field>
                        </div>
                    </div>
                    <!-- fin fragmento provisional -->
                    
                    <!-- IMPORTANDO MEDICIONES. Linea para busqueda actividad y fecha efectiva -->
                    <div class="flex w-full mt-4 mb-0"
                        v-if="form.type == 'certifiers' || form.type == 'chapters' || form.type == 'certifiers-chapters'">
                        <div class="flex w-full">
                            <b-field class="w-full">
                                <b-autocomplete required :placeholder="$t('planning_code') + ' ' + $t('activity')"
                                v-model="form.activityCode" minlength="3" maxlength="255" expanded :disabled="disableActivityCode"
                                :data="filteredActivities" @input="getFilteredActivities">
                                </b-autocomplete>
                            </b-field>
                        </div>
                        <div class="flex w-full ml-4">
                            <b-field class="w-full">
                                <b-datepicker icon="calendar" icon-pack="fas" type="month" v-model="effectiveDate" :placeholder="'fecha efectiva'"
                                    :month-names="$t('months_names')" :day-names="$t('days_names')"
                                    :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false" :max-date="previousMonth">
                                </b-datepicker>
                            </b-field>
                        </div>
                    </div>


                    <!-- import buttons -->
                    <div class="flex w-full" v-if="entityType == 'assignment' && form.type != 'resources_bcs'">
                        <div class="flex mr-2">
                            <v-button :loading="isLoading" @click="submit()" type="button" color="green" icon="file-import" icon-pack="fas">{{
                                $t('import') }}</v-button>
                        </div>
                    </div>
                    <div class="flex w-full" v-else>
                        <div class="flex mr-2">
                            <v-button :loading="isLoadingXLSX" type="submit" color="green" icon="upload" icon-pack="fas">{{
                                $t('load_xlsx') }}</v-button>
                        </div>
                        <div v-if="!isValidated" class="flex">
                            <v-button :loading="isLoading" :disabled="!xlsxLoaded" @click="validate()" type="button" color="green" icon="check" icon-pack="fas">{{
                                $t('validate') }}</v-button>
                        </div>
                        <div v-else class="flex">
                            <v-button :loading="isLoading" :disabled="!xlsxLoaded" @click="submit()" type="button" color="green" icon="file-import" icon-pack="fas">{{
                                $t('import') }}</v-button>
                        </div>
                    </div>

                    <!-- progress bar -->
                    <div class="progress-bar-container" v-if="inProgress">
                        <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
                    </div>
                </form>
            </div>
            <div>
                <b-tabs type="is-boxed" v-model="importActiveTab">
                    <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="box-open"></b-icon>
                            <span>{{ $t('imports_archive') }}</span>
                        </template>
                        <div>
                            <entity-imports-archive v-if="withHistory" :project-id="projectId" :partner-id="partnerId"
                                :company-id="companyId" :entity-type="entityType" :overwrite-field="true"
                                ref="entity_imports"></entity-imports-archive>
                        </div>
                    </b-tab-item>
                    <!-- Agregando nuevo tag que servira para poder ver el excel en una tabla  -->
                    <b-tab-item :disabled="excelTabEnabled">
                        <template slot="header">
                            <div v-if="entityType == 'assignment' && form.type != 'resources_bcs'">
                                <b-icon pack="fas" icon="exclamation-circle"></b-icon>
                                <span>
                                    {{ $t('error_log') }}
                                    <b-tag rounded>{{ errorsCount }}</b-tag>
                                </span>
                            </div>
                            <div v-else>
                                <b-icon pack="fas" icon="eye"></b-icon>
                                <span> {{ $t('excel_preview') }} </span>
                            </div>
                        </template>
                        <div v-if="entityType == 'assignment' && form.type != 'resources_bcs'">
                            <div class="flex items-center font-bold text-red-dark text-ml mb-4">
                                <span>{{ $t('error_import_title') }}</span>
                            </div>
                            <div v-for="(error, index) in errors" :key="'error_' + index">
                                <ul>
                                <li v-for="(msg, index) in error.messages" :key="'errorli_' + index" class="menu-header mb-2">
                                    <div class="flex items-center">
                                    <div class="text-red-dark text-ml">
                                        <span>
                                        {{ $t('import_row_title', [error.row]) }}.
                                        {{ msg }}
                                        </span>
                                    </div>
                                    </div>
                                </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else>
                            <import-excel-preview ref="previewDataExcel" :file="form.excelFile"
                                :overwrite="!!form.overwrite" :projectId="projectId" :entityType="entityType" :type="form.type" @row-changed="rowChanged()"></import-excel-preview>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </section>
</template>

<script>
import Form from "vform";
import entityImportsArchive from "./entity-imports-archive";
import ImportExcelPreview from '../components/import-excel-preview'
import axios from 'axios';
import * as XLSX from 'xlsx';
import {mapGetters} from 'vuex'
import moment from "moment";

export default {
    name: "import-entities",
    components: {
        entityImportsArchive,
        ImportExcelPreview
    },

    props: {
        withHistory: { type: Boolean, default: true },
        projectId: { type: Number, default: null, required: false },
        partnerId: { type: Number, default: null, required: false },
        companyId: { type: Number, default: null, required: false },
        entityId: { type: String, default: '', required: false }, // mediciones certificadoras van sobre una actividad concreta
        entityType: { type: String, required: true },
        canOverwrite: { type: Boolean, default: false },
        actionType: { type: String, default: '' }, // distinciones a tener en cuenta por el importador
        morphType: { type: String, default: '' }, // usado para cuando se tenga que trabajar con importaciones polimorphicas
        assignmentType: { type: String, default: 'certifiers' }, // para cargar certificadoras se requiere que por defecto sea seleccionado
    },

    created() {
        // si pasamos un codigo actividad, para importar cantidades ejecutadas sobre una medicion (desde la modal)
        if (this.entityId !== '') {
            this.form.activityCode = this.entityId
            this.form.overwrite = 1 // para cargar cantidades ejecutadas se requiere esta opcion a true
            this.form.assignmentType = this.assignmentType
            this.disableActivityCode = true

        }
    },

    mounted() {
        this.getTemplates()
        this.getTypes()
        this.loadImportStatus()
        this.form.assignmentType = this.form.type
    },

    watch: {
        importEntities: {
            handler(newValue, oldValue) {
                this.loadImportStatus();
            },
            deep: true
        }
    },

    data: () => ({
        isLoading: false,
        form: new Form({
            projectId: null,
            partnerId: null,
            companyId: null,
            excelFile: null,
            description: "",
            overwrite: false,
            assignmentType: null,
            activityCode: null,
            actionType: "",
            workType: null,
            effectiveDate: "",
            type: "",
            reset: false
        }),
        effectiveDate: null,
        errors: [],
        importActiveTab: 0,
        errorsCount: 0,
        disableActivityCode: false,
        filteredActivities: [],
        excelTabEnabled: true,
        isValidated: false,
        xlsxLoaded: false,
        isLoadingXLSX: false,
        files: [],
        downloadModal: false,
        types: [],
        chunkSize: 500,
        inProgress: false,
        progress: 0,
        timeout: null,
    }),

    computed: {
        ...mapGetters({
            importEntities: 'app/importEntities'
        }),
        templateName() {
            // TODO: de momento hacemos este hack para mantener funcionando (replace('/', '-')) lo ideal es quitar el replace y mantener una convencion para entitytype
            return this.assetVapor("template_import_" + this.entityType.replace('/', '-') + ".xlsx");
        },
        templateFileName() {
            // TODO: de momento hacemos este hack para mantener funcionando (replace('/', '-'))
            return this.$t(this.entityType.replace('/', '-') + "_import_template") + ".xlsx";
        },
        overwriteEnabled() {
            return this.canOverwrite;
        },
        previousMonth() {
            let today = new Date()
            return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
        }
    },

    methods: {
        async submitForm() {
            this.importActiveTab = 1;
            this.excelTabEnabled = false;
            this.isLoadingXLSX = true;
            this.xlsxLoaded = false;
            this.isValidated = false;
            this.form.reset = true;
            try {
                let response = await this.$refs.previewDataExcel.processFile();
                this.isLoadingXLSX = false;

                if (response.success) {
                    this.xlsxLoaded = true;
                } else {
                    this.$notify.error("error_import");
                }
            } catch (error) {
                this.isLoadingXLSX = false;
                this.$notify.error("error_import");
            }
        },

        cleanErrors() {
            this.importActiveTab = 0;
            this.excelTabEnabled = true;
            this.form.descripcion = '';
            if (this.$refs.previewDataExcel?.previewData) {
                this.$refs.previewDataExcel.previewData = [];
            }
        },

        
        downloadTemplateByName(filename) {
            let link = document.createElement("a");
            link.href = this.assetVapor(filename);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        
        },

        downloadTemplate() {
            if (this.files.length) {
                this.downloadModal = true;
                return;
            }
            let link = document.createElement("a");
            link.href = this.templateName;
            link.download = this.templateFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        async validate() {
            this.isLoading = true
            try {
                let validatedData = []
                let mappedData = this.mapFields(this.$refs.previewDataExcel.previewData, this.$refs.previewDataExcel.getFields())
                let hasErrors = false

                for (let i = 0; i < mappedData.length; i += this.chunkSize) {
                    let chunk = mappedData.slice(i, i + this.chunkSize);
                    let { data } = await axios.post(`/api/v2/import/${this.entityType}/optimized/validate`, {
                        data: chunk,
                        project_id: this.projectId,
                        with_update: this.form.overwrite,
                        type: this.form.type,
                        description: this.form.description,
                        is_last: (i + this.chunkSize) >= mappedData.length,
                        ...this.form,
                        reset: i == 0,
                    });

                    if ((data.success == false)) {
                        hasErrors = true;
                    }
                    validatedData = data.data
                }

                this.$refs.previewDataExcel.previewData = this.mapFields(validatedData, this.$refs.previewDataExcel.getFields(), false);
                if (hasErrors) {
                    this.$notify.error("error_import_validate");
                } else {
                    this.$notify.success("success_import_validate");
                    this.isValidated = true;
                }
            } catch (error) {
                console.log(error)
                this.$notify.error("error_import_validate");
            }
            this.isLoading = false
        },

        async submit() {

            if (this.entityType == 'assignment' && this.form.type != 'resources_bcs') {
                await this.importData()
            } else {
                await this.importDataOptimized()
            }

        },

        async importDataOptimized() {
            if (this.isValidated) {
                this.isLoading = true
                this.progress = 0
                this.inProgress = true
                this.$refs.previewDataExcel.updateDataStatus(this.$refs.previewDataExcel.previewData.length - 1, 'progress')
                let mappedData = this.mapFields(this.$refs.previewDataExcel.previewData, this.$refs.previewDataExcel.getFields())
                try {
                    for (let i = 0; i < mappedData.length; i += this.chunkSize) {
                        let chunk = mappedData.slice(i, i + this.chunkSize);
                        let { data } = await axios.post(`/api/v2/import/${this.entityType}/optimized`, {
                            data: chunk,
                            project_id: this.projectId,
                            with_update: this.form.overwrite,
                            type: this.form.type,
                            description: this.form.description,
                            is_last: (i + this.chunkSize) >= mappedData.length,
                            ...this.form,
                            reset: i == 0,
                        });
                    }

                    let importEntities = self.importEntities || {}
                    if (!importEntities[this.entityType]) {
                        importEntities[this.entityType] = {}
                    }
                    let chunkProgress = (100 / (Math.ceil(mappedData.length / 50)))
                    importEntities[this.entityType][`${this.projectId}`] = { status: 'pending', data: mappedData, type: this.form.type, chunkProgress: chunkProgress, progress: 0, lastNotification: Date.now()}
                    this.$store.dispatch('app/setImportEntities', {importEntities})

                    this.$notify.success("import_in_progress");
                } catch (error) {
                    this.$notify.error("error_import");
                    this.isLoading = false
                    this.inProgress = false
                }
            }
        },

        async importData() {
            this.isLoading = true;
            var self = this;
            this.cleanErrors();

            this.form.projectId = this.projectId;
            this.form.partnerId = this.partnerId;
            this.form.companyId = this.companyId;
            this.form.actionType = this.actionType;
            this.form.effectiveDate = this.effectiveDate
                ? moment(this.effectiveDate).format('YYYY-MM-DD')
                : "";
            if (this.morphType)
                this.form.morphType = this.morphType
            this.form
                .post("/api/v2/import/" + this.entityType)
                .then(function (response) {

                    self.isLoading = false;
                    if (response.data.success) {

                        self.$notify.success("success_import");
                        self.form.description = "";
                        if (self.withHistory)
                        self.$refs.entity_imports.reloadImports();
                        self.$emit("success");
                    } else {

                        self.$notify.error("error_import");

                        // Procesamos los errores para mostrarlos
                        for (let key in response.data.errors) {
                            let error_info = key.split(".");
                            let row = parseInt(error_info[0]);
                            let field = `[${error_info[1]}]`;//self.$t(error_info[1]);

                            let messages = response.data.errors[key].map(function (msg) {
                                return self.$t(msg) !== msg
                                ? self.$t(msg, [field])
                                : msg; // prefiero el msg tal cual se devuelve en el importador
                                // : self.$t("import_generic_error_msg", [parseInt(field)+1]); // mensaje generico sin muchas pistas
                            });

                            self.errorsCount = self.errorsCount + messages.length;

                            self.errors.push({ row: row, field: field, messages: messages });
                        }

                        self.errors = self.errors.sort(function (a, b) {
                            return parseInt(a.row) - parseInt(b.row);
                        });

                        self.importActiveTab = 1;
                    }
                })
                .catch(error => {
                    self.isLoading = false;
                    self.$notify.error("error_import");
                });

            this.form.excelFile = null;
        },

        rowChanged() {
            this.isValidated = false;
        },

        resetValidation() {
            this.isValidated = false
            this.form.assignmentType = this.form.type
            if (this.$refs.previewDataExcel?.previewData) {
                this.$refs.previewDataExcel.updateDataStatus(this.$refs.previewDataExcel.previewData.length - 1, 'start')
            }
        },

        loadImportStatus() {
            if (!this.importEntities || !this.importEntities[this.entityType]) {
                return true
            }
            let importEntities = this.importEntities[this.entityType][`${this.projectId}`]
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            if (importEntities && importEntities.status) {
                this.importActiveTab = 1;
                this.excelTabEnabled = true;
                this.xlsxLoaded = true;
                this.$set(this.form, 'type', importEntities.type);
                let errors = importEntities.errors || {}

                let data = this.mapFields(importEntities.data || [], this.$refs.previewDataExcel.getFields(importEntities.type), false);
                this.$refs.previewDataExcel.setData(data)

                if (importEntities.status == 'error') {
                    this.isLoading = false
                    this.isValidated = false
                    this.$refs.previewDataExcel.updateDataStatus(this.$refs.previewDataExcel.previewData.length - 1, 'validated')
                    this.$refs.previewDataExcel.updateDataErrors(errors)
                } else {
                    this.isLoading = true
                    this.isValidated = true
                    this.inProgress = true
                    this.progress = importEntities.progress
                }
                if (importEntities.lastImportedIndex) {
                    this.$refs.previewDataExcel.updateDataStatus(importEntities.lastImportedIndex, 'imported')
                }
                if (importEntities.status == 'pending') {
                    this.watchImportTime(importEntities)
                }
            } else {
                this.cleanErrors();
                this.form.excelFile = null;
                this.xlsxLoaded = false
                this.isLoading = false
                this.isValidated = false
                this.inProgress = false
                this.progress = 0
                this.$refs.entity_imports.reloadImports();
            }
        },

        watchImportTime(importEntities) {
            let lastTime = importEntities.lastNotification
            let timeout = lastTime + (10*60*1000)
            let currentTimeout = timeout - Date.now()

            if (currentTimeout <= 0) {
                this.resetImport()
            } else {
                this.timeout = setTimeout(() => {
                    this.resetImport()
                }, currentTimeout);
            }
        },

        resetImport() {
            this.cleanErrors();
            this.form.excelFile = null;
            this.xlsxLoaded = false
            this.isLoading = false
            this.isValidated = false
            this.inProgress = false
            this.progress = 0

            let importEntities = this.importEntities || {}
            importEntities[this.entityType][`${this.projectId}`] = {}
            this.$store.dispatch('app/setImportEntities', {importEntities})

            this.$notify.error('error_import');
        },

        async getFilteredActivities() {
            if (this.form.activityCode && this.form.activityCode.length > 2) {
                let getParams = {
                    p: this.projectId,
                    filter_planning_code: this.form.activityCode,
                    light: 1
                }
                const { data } = await axios.get('/api/v2/activities', { params: getParams });
                this.filteredActivities = data.activities.map(activity => activity.planning_code);
            }

            else {
                this.filteredActivities = [];
            }
        },

        mapFields(dataArray, structure, toIndex = true) {
            let baseFields = {
                skip: 0,
                import_status: 1,
                status_error: 2,
                actions: 3,
            };

            let finalStructure = {
                ...baseFields,
                ...Object.fromEntries(
                    Object.entries(structure).map(([key, value]) => [key, value + 4])
                ),
            };

            if (!toIndex) {
                finalStructure = Object.fromEntries(
                        Object.entries(finalStructure).map(([key, value]) => [value, key])
                    )
            }

            return dataArray.map(item => {
                let mappedItem = {};
                for (let [key, index] of Object.entries(finalStructure)) {
                    mappedItem[index] = item[key]
                }
                return mappedItem;
            });
        },

        // TODO: Aqui van metodos que cargan configuracion para cada entidad (esto deberia luego ser movido a alguna interfaz o parecido que maneje esto de manera mas limpia)
        getTemplates() {
            switch (this.entityType) {
                case 'activities': 
                    this.files = [
                        { filename:"template_import_activities_simple.xlsx", name:"simple" },
                        { filename:"template_import_activities_prelations.xlsx", name:"with_linked_activities" },
                    ]
                    break;
                case 'assignment': 
                    this.files = [
                        { filename:"template_import_recursos_(BC3).xlsx", entityType: "assignment", name:"resources_bc3" },
                        { filename:"template_import_mediciones_Simple.xlsx", entityType: "assignment", name:"simple" },
                    ]
                    break;
            }
        },

        getTypes() {
            switch (this.entityType) {
                case 'activities': 
                    this.types = [
                        {value: 'simple', 'name': 'simple'},
                        {value: 'prelations', 'name': 'with_linked_activities'}
                    ]
                    this.form.type = 'simple'
                    break;
                case 'assignment': 
                    this.types = [
                        {value: 'task', 'name': 'tasks'},
                        {value: 'resources_bc3', 'name': 'resources_bc3'},
                        {value: 'certifiers', 'name': 'certifiers'},
                        {value: 'resources_bcs', 'name': 'simple'},
                        {value: 'chapters', 'name': 'chapters'},
                        {value: 'certifiers-chapters', 'name': 'certifiers_chapters'}
                    ]
                    this.form.type = 'task'
                    break;
            }
        }
    }
};
</script>
<style scoped>
.progress-bar-container {
    margin-top: 10px;
    width: 100%;
    height: 7px;
    background-color: #eee;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}

.progress-bar {
    height: 100%;
    background-color: #54e086;
    transition: width 1s ease;
}
</style>