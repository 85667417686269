<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button @click="() => { showFormImportEntities = true }" icon="file-import" :disabled="showFormData">{{
        $t('import_suppliers') }}</v-button>

      <v-button @click="addProvider" icon="plus" class="ml-4" :disabled="showFormImportEntities">{{ $t('add_supplier') }}</v-button>
    </div>

    <div class="flex">
      <div :class="[(showFormData || showFormImportEntities ? 'w-7/12' : 'w-full'), 'mr-2']">
        <providers ref="providers_list" :projectId="projectId" :emitParentList="true" @epc-edit="handleEditEntity" />
      </div>

      <transition name="fade">
        <div class="w-5/12 ml-2" v-if="showFormData">
          <provider-edit :projectId="projectId" :provider="provider" @epc-close="handleOnCloseEdit"
            @epc-success-creating="handleSuccess" />
        </div>

        <div class="w-5/12 ml-2" v-if="showFormImportEntities">
          <providers-import :projectId="projectId" :entity-type="'providers'"
            @epc-close="(event) => { showFormImportEntities = false }"
            @epc-success-import="() => { showFormImportEntities = false; $refs.providers_list.reload() }" />
        </div>

      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import { mapGetters } from "vuex";
import Providers from './providers.vue';
import ProviderEdit from './provider-edit.vue';
import ProvidersImport from './providers-import.vue';

export default {
  name: "manage-providers",

  components: {
    Providers,
    ProviderEdit,
    ProvidersImport,
  },

  data: () => ({
    provider: null, // objeto que se crea
    showFormData: false,
    showFormImportEntities: false,
    showFormLink: false,
  }),

  props: {
    projectId: { type: Number, required: true }
  },

  computed: {
    ...mapGetters({
      company: "app/company",
    }),
  },

  created() {
  },

  mounted() {
  },

  methods: {
    addProvider() {
      this.provider = null
      this.showFormData = true;
    },


    handleOnCloseEdit(event) {
      this.showFormData = !event
    },

    handleOnCloseLinkGroup(event) {
      this.showFormLink = !event
    },

    handleSuccess(entity) { // recibe la entidad editada
      this.showFormData = false
      this.$emit("epc-store-providers");
      this.$refs.providers_list.reload()
    },

    handleEditEntity(entity) {
      this.provider = entity
      this.showFormData = true;
    },

    handleAddProvider(provider) {
      this.provider = provider
      this.showFormData = true;
    },

    handleEditChapter(provider) {
      this.provider = provider
      this.showFormData = true;
    },
  }

};
</script>

<style scoped></style>
